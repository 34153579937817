import { Link } from "components/Link"

const Navbar = () => {
    return (
        <div data-collapse="all" data-animation="default" data-duration="400" data-doc-height="1" data-easing="ease"
            data-easing2="ease" role="banner" className="navbar-animated w-nav">
            <div className="w-container">
                <Link to="/" aria-current="page" className="brand-4 w-nav-brand w--current" rel={"dofollow"}>
                <div className="text-block-14">CALEB N.</div>
            </Link>
                <div data-w-id="1fb34bde-01b6-f2f1-5eb7-2de863e66763"
                    className="nav-button-animated w-hidden-main w-hidden-medium w-nav-button">
                    {/* <nav role="navigation" className="nav-menu-3 w-nav-menu">
                        <div className="full-screen-menu-overlay">
                            <Link to="/websites" data-w-id="1fb34bde-01b6-f2f1-5eb7-2de863e66766" className="mobile-nav-link w-nav-link" rel={"dofollow"}>Websites</Link>
                            <Link to="/mobile" data-w-id="1fb34bde-01b6-f2f1-5eb7-2de863e66768" className="mobile-nav-link w-nav-link" rel={"dofollow"}>Mobile Apps</Link>
                            <Link to="/belize" data-w-id="1fb34bde-01b6-f2f1-5eb7-2de863e66768" className="mobile-nav-link w-nav-link" rel={"dofollow"}>Belize</Link>
                            <Link to="/about" data-w-id="1fb34bde-01b6-f2f1-5eb7-2de863e6676c" className="mobile-nav-link w-nav-link" rel={"dofollow"}>About</Link>
                        </div>
                    </nav> */}
                    <div className="top-line-2"></div>
                    <div className="middle-line-2"></div>
                    <div className="bottom-line-2"></div>
                </div>
                {/* <div className="div-block-14 w-hidden-small w-hidden-tiny">
                    <Link to="/websites" className="link-3 rev nav" rel={"dofollow"}>Websites</Link>
                    <Link to="/mobile" className="link-3 rev nav" rel={"dofollow"}>Mobile Apps</Link>
                    <Link to="/belize" className="link-3 rev nav" rel={"dofollow"}>Belize</Link>
                    <Link to="https://shop.calebnicholas.com" className="link-3 rev nav" rel={"dofollow"} target="_blank">Shop</Link>
                    <Link to="/about" className="link-3 edge rev" rel={"dofollow"}>ABOUT</Link></div> */}
            </div>
        </div>
    );
};

export default Navbar;