import React from "react";
import GenericLayout from "layouts/Generic/Generic.layouts";
import Headliner from "sections/Headliner/PersonalHeadliner/PersonalHeadliner.sections";
import RecentBrands from "sections/RecentBrands/RecentBrands.sections";
// import ShowcaseProjects from "sections/Projects/ShowcaseProjects.sections.tsx/ShowcaseProjects.sections";

const Homepage = () => {
    return (
       <GenericLayout>
           <React.Fragment>
              <Headliner />
              <RecentBrands visible={false}/>
              {/* <ShowcaseProjects />  */}
           </React.Fragment>
       </GenericLayout>
    );
}

export default Homepage;